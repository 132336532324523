body {
  background-color: #1d1e21;
  min-height: 100%;
  color: white;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
}
html {
  height: 100%;
}
#root {
  height: 100%;
}

.side-nav {
  top: 150px;
  position: sticky;
  align-self: flex-start;
  align-items: center;
  justify-content: end;
}
@media (min-width: 1000px) {
  .side-nav {
    padding-left: 100px;
  }
}

.side-nav-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
  margin-right: 10%;
  max-width: 385px;
}
.side-nav-wrapper > * + * {
  margin-top: 15px;
}
.wrapper {
  display: flex;
  margin-top: 30vh;
  padding: 0 5% 0 5%;
}
.main-container {
  padding: 0 5% 5% 5%;

  display: flex;
  flex-direction: column;
}
.intro {
  display: flex;
  flex-flow: column;
  align-items: start;
  justify-content: center;
}
.intro-text {
  font-weight: bold;
  width: 600px;
  max-width: 100%;
  text-align: left;
  line-height: 1.5em;
}
.scroll-icon {
  display: block;
  margin-top: 100px;
}
.btn {
  padding: 10px 30px 10px 30px;
  border-radius: 5px;
  display: block;
  font-family: 'Ubuntu', sans-serif;
  min-width: 0%;
  font-size: 1.1rem;
}

.btn:focus {
  box-shadow: none !important;
}
.section {
  margin-top: 200px;

  font-size: 1.1rem;
}

.section-title {
  color: #b3b2b2;
  font-size: 1.5rem;
}
.content {
  line-height: 2em;
  margin-top: 30px;
  font-size: 1.25rem;
}

.projects {
  margin-top: 10%;
}

.project-cards {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.project-cards > * + * {
  margin-top: 80px;
}

.card {
  width: 100%;
  border-radius: 20px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  -webkit-justify-content: flex-end;
  border: 0;
  cursor: pointer;
}

.card-tab {
  padding: 20px;
}

.card-title {
  font-size: 2rem;
}

.card-techs {
  display: flex;
  flex-wrap: wrap;
  color: #b3b2b2;
  margin: 10px 0px 10px 0;
}
.card-techs > * {
  margin-right: 20px;
}

.card-links {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.card-links > * {
  margin-right: 20px;
}
.action-btn {
  border: 2px solid white;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  font-size: 0.8rem;
  border-radius: 50rem;
  white-space: nowrap;
  font-size: 1rem;
}
a {
  text-decoration: none !important; /* The !important is to ensure the Bootstrap styles are overridden */
}

.resume-title {
  margin: 50px 0 30px;
  font-size: 1.1rem;
}

.job-title-wrapper {
  display: flex;
  justify-content: space-between;
  padding-right: 40%;
}
.job-title {
  font-size: 1.3rem;
}
.job-duration {
  color: gray;
  white-space: nowrap;
  margin-left: 10px;
}
.job-block {
  width: 100%;
}
.job-desc {
  line-height: 1.5rem;
  font-size: 1.1rem;
  margin: 10px 0 10px;
}
.skill-icons {
  max-width: 100%;
  margin-left: -18px;
  display: flex;
  flex-wrap: wrap;
}
.contact-input {
  width: 400px;
  max-width: 100%;
  border: none;
  border-radius: 50rem;
  padding: 10px 20px 10px 20px;
  margin-bottom: 30px;
}
.contact-textarea {
  width: 400px;
  max-width: 100%;
  border: none;
  border-radius: 1rem;
  padding: 10px 20px 10px 20px;
  margin-bottom: 20px;
}

#contact {
  margin-bottom: 40vh;
}
#videoDiv2 {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  display: block;
  position: relative;
} /* This has been changed */
#video2 {
  width: 100%;
  height: auto;
} /* This has been changed */
#videoMessage2 {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

body {
  -webkit-animation: theme 21s linear infinite;
  animation: theme 21s linear infinite;
}
body:after,
body:before {
  content: '';
  display: block;
  position: fixed;
  z-index: -1;
  top: 0;
  width: 100vw;
  height: 100vh;
  width: 100vmax;
  height: 100vmax;
  background: rgba(0, 0, 0, 0.1);
  -webkit-animation: background 90s linear infinite;
  animation: background 90s linear infinite;
}
body:after {
  left: 15vw;
}
body:before {
  right: 15vw;
  -webkit-animation-delay: -30s;
  animation-delay: -30s;
  animation-direction: reverse;
}

@-webkit-keyframes theme {
  0% {
    background: #1d1e21;
  }
  100% {
    background: #808080;
  }
}

@keyframes theme {
  0% {
    background: #323336;
  }
  100% {
    background: #323336;
  }
}
@-webkit-keyframes background {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes background {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.skill-img {
  height: 68px;
  width: 68px;
  margin: 10px;
}

/*Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .scroll-icon {
    display: none !important;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
